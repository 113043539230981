<template>
<div>
	<CRow>
		<CCol sm="6" md="6">
        <CCard>
          <CCardHeader>
            <h4>Add New Fee</h4>
          </CCardHeader>
          <CCardBody>

            <CRow>
              <CCol col="12" class="text-left">
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol>
            </CRow>

            <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Transaction Type</label>
                  </b-col>
                  <b-col sm="9">
                      <b-form-select @change="onChangeAmount" v-model="trType" :options="optTrType"></b-form-select>
                  </b-col>
            </b-row><br/>

             <!-- <b-row>
                  <b-col sm="3">
                     <label for="input-small" >User Groups</label>
                  </b-col>
                  <b-col sm="9">
                      <b-form-select v-model="userGroup" :options="optUserGroup"></b-form-select>
                  </b-col>
             </b-row><br/> -->

             <b-row>
                  <b-col sm="3">
                     <label for="input-small" >User Tier</label>
                  </b-col>
                  <b-col sm="9">
                      <b-form-select @change="onChangeAmount" v-model="usrLvl" :options="optUsrLvl"></b-form-select>
                  </b-col>
             </b-row><br/>

             <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Fee Type</label>
                  </b-col>
                  <b-col sm="9">
                      <b-form-select @change="onChangefeeType" v-model="feeType" :options="optFeeType"></b-form-select>
                  </b-col>
             </b-row><br/>

             <CInput
                @change="onChangeAmount"
                type="number"
                label="Amount"
                min=0 step="any"
                v-model="amount"
                horizontal
              />

              <CInput
                v-if="seenMinAmount"
                @change="onChangeMinAmount"
                type="number"
                label="Min Amount"
                min=0 step="any"
                v-model="minAmount"
                horizontal
              />

             <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Status</label>
                  </b-col>
                  <b-col sm="9">
                      <b-form-select v-model="status" :options="optStatus"></b-form-select>
                  </b-col>
             </b-row><br/>

             <b-row>
                  <b-col sm="3"></b-col>
                  <b-col sm="9">
                       <b-button-group>
                         <router-link to="/settings/fee" tag="b-button">Cancel</router-link>
                         <b-button :disabled="isBtnDisabled"  v-on:click="actionSubmit()" variant="primary">Submit</b-button>
                       </b-button-group>
                  </b-col>
             </b-row><br/>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

</div>
</template>

<script>
 import AuthService from '@/api/AuthService.js';
 import TransactService from '@/api/TransactService.js';
 import PromoService from '@/api/PromoService.js';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

  export default {
    components: {
      VueTypeaheadBootstrap,
    },
    data() {
      return {
          roles:localStorage.getItem('roles').split(","),
          isBtnDisabled:false,
          seenMinAmount:false,
          submit:true,
          feeType:'',
          optFeeType:[],
          trType:'',
          optTrType:[],
          status:'',
          optStatus:[],
          usrLvl:'',
          optUsrLvl:[],
          amount:0,
          minAmount:0,
          seen:false,
          msg :'',
          color:''
      };
    },
    created(){
       this.getConfigList();
    },

    methods: {

      isValInteger(n){
        var result = (n - Math.floor(n)) !== 0;
        if (result)
          return false;
        else
          return true;
      },

      countDecimals(value){
        if(this.isValInteger(value)){
          return 0;
        } else {
          return value.toString().split(".")[1].length || 0;
        }
      },

      onChangefeeType(){
          if(this.feeType === "PERCENTAGE"){
             this.seenMinAmount = true;
          } else {
             this.seenMinAmount = false;
             this.minAmount = 0;
          }
      },

      onChangeMinAmount(){
        if(!Number.isInteger(this.minAmount)){
          if(this.countDecimals(this.minAmount) > 2){
              this.msg   = "Do not allow entry amount of more than 2 decimal places.";
              this.color = "warning"; this.seen=true;  this.submit = false;
              setTimeout( () =>  this.seen=false , 5000);
              this.minAmount = parseFloat(this.minAmount).toFixed(2);
          }
        }
      },

      async onChangeAmount(){

          /*Prevent number has more than 2 decimal palces*/
          if(!Number.isInteger(this.amount)){
            if(this.countDecimals(this.amount) > 2){
                this.msg   = "Do not allow entry amount of more than 2 decimal places.";
                this.color = "warning"; this.seen=true;  this.isBtnDisabled = true;
                setTimeout( () =>  this.seen=false , 5000);
                this.amount = parseFloat(this.amount).toFixed(2);
            }
          }

          var promoType = (this.trType=="TOP_UP") ? "TOPUP" : this.trType;
          const resp = await PromoService.getpromoByType(promoType);
          if(resp.message == "Success"){
                var validateFee  =[];  var validateTier =[]; this.submit = true;
                var fee_amount = this.amount; var usrLvl = this.usrLvl;
                var data = resp.payload;
                for (var key in data) {
                    var bentype  = data[key].benefit.benefitType;
                    var userTier = data[key].userTier;
                    if(bentype == "DISCOUNT_FIXED") {
                        var promo_amount = data[key].benefit.amount;
                        console.log('fee :' +fee_amount + ' < amount:' + promo_amount+' ?');                        
                        var isFeeValid  = (fee_amount < promo_amount) ? false : true;
                        validateFee.push(isFeeValid);
                        if(data[key].limitedToTier){
                           console.log('form :' +usrLvl + '| promo:' + userTier);
                           var isTierValid = (usrLvl != userTier) ? false : true;
                           validateTier.push(isTierValid);
                        }                         
                    }
                }

                if(validateFee.includes(false) || validateTier.includes(false)){
                    if(validateFee.includes(false)){
                        this.msg = "Amount not allow a lower than Promos !";
                    } else { this.msg = "Tier not match with the Promos !"; }
                    this.color = "warning"; this.seen=true;  this.isBtnDisabled = true;
                    setTimeout( () =>  this.seen=false , 5000);
                } else { this.isBtnDisabled = false; }
          }
      },

      resetField(){
        this.amount    = '';
        this.feeType   = '';
        this.status    = '';
        this.trType    = '';
        this.userGroup = '';
        this.usrLvl    = '';
      },

      actionSubmit(){
        let params = {};  var validate = [];
        params['feeType'] = this.feeType;
        params['recordStatus'] = this.status;
        params['transactionType'] = this.trType;
        params['userTier'] = this.usrLvl;

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }
        validate.push(this.submit);

        params['amount'] = parseFloat(this.amount);
        params['minAmount'] = parseFloat(this.minAmount);

        if(!validate.includes(false)){   //console.log(params);
           if(this.roles.includes("ROLE_MANAGE_TRANSACTION_FEE")){
               this.saveTrFee(params);
            } else {
              this.msg = 'Sorry, your role not allowed this action!';
              this.color = 'warning'; this.seen  = true;
            }
        } else {
           var msg1 = "Amount not allow a lower than Promo or Tier Not match with the Promos!";
           var msg2 = 'Data Not Complete, All fields required !';
           this.msg = (!this.submit) ? msg1 : msg2;
           this.color = 'warning'; this.seen  = true;
        }
        setTimeout( () =>  this.seen=false , 5000);
      },

      sortByProperty(property) {
        return function (a, b) {
          if (a[property] > b[property])
            return 1;
          else if (a[property] < b[property])
            return -1;
          return 0;
        }
      },

      saveTrFee: function(params) {
           TransactService.saveTrFee(params).then(resp => {  //console.log(resp);

              if(resp.message=='Success'){
                this.msg   = 'Save New Fee Successfully ';
                this.color = 'success';  this.seen  = true;
                this.resetField();
              } else {
                this.msg   = !resp.data.message ? "Failed Save" : resp.data.message;
                this.color = 'warning'; this.seen  = true;
              }

              setTimeout( () =>  this.seen=false , 5000);

           }, error => {
              console.log('error');
          });
      },

      getConfigList: function() {
          AuthService.getConfigList().then(resp => {  //console.log(resp);

            var txnFeeType = resp.txnFeeType; var txnFeeTypeSrc=[];
            for (var key in txnFeeType) {
                var txnFeeTypeName = txnFeeType[key];
                txnFeeTypeSrc.push({ value: txnFeeTypeName , text: txnFeeTypeName });
            }
            this.optFeeType = txnFeeTypeSrc;

            var usrTLType = resp.userTierList; var userTLSrc=[];
            for (var key in usrTLType) {
                var usrTLName = usrTLType[key];
                userTLSrc.push({ value: usrTLName , text: usrTLName });
            }
            this.optUsrLvl = userTLSrc;

            var trtype = resp.txnFeeTxnType; var trTypeSrc=[];
            for (var key in trtype) {
                var trTypeName = trtype[key];
                trTypeSrc.push({ value: trTypeName , text: trTypeName });
            }
            trTypeSrc.sort(this.sortByProperty("value"));
            this.optTrType = trTypeSrc;

            var acceptSts= ["ACTIVE","INACTIVE"];
            var recSts = resp.recordStatus; var recStsSrc=[];
            for (var key in recSts) {
                var recStsName = recSts[key];
                if(acceptSts.includes(recStsName)){
                  recStsSrc.push({ value: recStsName , text: recStsName });
                }
            }
            this.optStatus = recStsSrc;


            // var usrGroup = resp.userGroups; var usrGroupSrc=[];
            // for (var key in usrGroup) {
            //     var usrGroupName = usrGroup[key];
            //     usrGroupSrc.push({ value: usrGroupName , text: usrGroupName });
            // }
            // this.optUserGroup = usrGroupSrc;

           }, error => {
            console.log('error');
          });
      },

    }
  }
</script>